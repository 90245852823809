import { Dictionary } from 'vue-router/types/router'

/* eslint no-unused-vars: 0 */
export enum State {
  None = 0,
  New = 2,
  Served = 3,
  Done = 4,
  Cancel = 5,
  NoShow = 6,
}

export enum ReservedBy {
  Direct = 1,
  Repeat = 2,
  Phone = 3,
  Hotpepper = 4,
  CallCenter = 5,
  ShopTablet = 6,
  Line = 7,
  PreOpen = 8,
  InShop2 = 9,
  App = 10,
  Other = 99,
}

export interface Rating {
  point: number
  freeComment: string
  created: string
  updated: string
}

// 使用染料1剤
export interface UsedDyestuff1 {
  dyestuff: number
  amount: number // g
  readonly name: string
  readonly brand: string
  readonly isActive: boolean
}
// 使用染料2剤
export interface UsedDyestuff2 {
  amount: number // g
  percentage: number
}

export interface OperationHistory {
  readonly id: string
  readonly isActive: boolean
  readonly date: string
  readonly shopName: string
  readonly menuName: string
  readonly treatmentText: string
  treatmentName: string
  readonly scalpCareName?: string

  scalpProtectiveAgent: number | null // 頭皮保護剤
  readonly scalpProtectiveAgentText: string
  scalpProtectiveAgentType: number | null
  readonly scalpProtectiveAgentTypeText: string

  usedDyestuffRoots: UsedDyestuff1[]
  usedSolutionRoots: UsedDyestuff2[]
  usedDyestuffEnds: UsedDyestuff1[]
  usedSolutionEnds: UsedDyestuff2[]

  treatmentStaff: string | null
  readonly treatmentStaffCode?: string
  readonly treatmentStaffName?: string

  dyeingStaff1: string | null
  readonly dyeingStaffCode1?: string
  readonly dyeingStaffName1?: string
  dyeingStaff2: string | null
  readonly dyeingStaffCode2?: string
  readonly dyeingStaffName2?: string
  shampooStaff: string | null
  readonly shampooStaffCode?: string
  readonly shampooStaffName?: string

  note: string
  noteForNext: string
  dyestuffAmountRoot: null | number
  dyestuffAmountEnd: null | number

  readonly createdAt: string
  readonly updatedAt: string
}

export type Reservation = {
  id: string
  isRemoved: boolean
  readonly start: string
  date: string
  time: string
  state: number
  reservedBy: ReservedBy

  readonly requestColor: string
  readonly requestColorText: string
  menu: string
  readonly menuName: string
  readonly rawMenuName: string
  treatment: string | null
  readonly treatmentName: string | null
  readonly menuOptionNames: string[]
  addtionalDyestuff: boolean
  scalpCare: number | null
  shop: string
  note: string

  attendedStaff: string | null
  staff: string
  shampooStaff: string | null

  // 予約者
  familyName: string
  givenName: string
  familyNameKana: string
  givenNameKana: string
  readonly fullName: string
  readonly fullNameKana: string
  // customer
  customer: string // customer id
  readonly customerUserEmail: string
  readonly customerNode: string
  readonly receivesInfo: null | boolean
  readonly customerDeleted?: boolean

  readonly starterPassFrom: null | string
  readonly starterPassExpiry: null | string
  readonly scalpProtectLastUsed: null | string

  // annotate
  readonly sumVisited: number
  readonly previousReservedDate?: string

  // reating
  readonly ratingPushNotified: string
  readonly rating: Rating

  // amount
  readonly isDiscounted: boolean
  readonly paid: boolean
  readonly subsucription: null | number

  // hotpeper
  readonly hotpepperNo: string | null
  readonly hotpepperPoint: number | null

  readonly createdAt: string
  readonly updatedAt: string

  readonly operationHistory: OperationHistory | null

  prev?: Reservation | null

  [key: string]: any
}

export interface ReservationLogUpdatedField {
  readonly label: string
  readonly name: string
  readonly oldValue: any
  readonly oldText: string | null
  readonly newValue: any
  readonly newText: string | null
}

export interface ReservationLog {
  readonly id: number
  readonly reservation: string
  readonly editor: {
    readonly id: string
    readonly uid: string
    readonly username: string
    readonly isShopUser: string
    readonly shopName: string
  }
  readonly data: Dictionary<any>
  readonly updatedFields: ReservationLogUpdatedField[] | null
  readonly created: string
}
